import React from 'react';

type ErrorText = {
  className?: string;
  errorText: string;
};

const ErrorText: React.FC<ErrorText> = (props) => {
  return (
    <span className={`text-start text-sm text-red-500 ${props.className}`}>
      {props.errorText}
    </span>
  );
};

export default ErrorText;
